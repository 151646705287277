import * as React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import { lazy } from "react";
import PrivateLayout from "../layouts/main";
import FormsLayout from "../layouts/forms";
import AuthLayout from "../layouts/auth";
import StudentInfo from "../pages/student";
import AdminLayout from "../layouts/admin";


const ApplicationPage = lazy(() => import("../pages/homepage"));
const Admission = lazy(() => import("../pages/admission"));

const Login = lazy(() => import("../pages/login"));
const Verify = lazy(() => import("../pages/verify"));

const ForgotPassword = lazy(() => import("../pages/forgotpassword"));
const Register = lazy(() => import("../pages/register"));
const ResetPassword = lazy(() => import("../pages/resetpassword"));
const Biodata = lazy(() => import("../pages/biodata"));
const Examination = lazy(() => import("../pages/examination"));
const Olevels = lazy(() => import("../pages/o-levels"));
const RegistrationSuccess = lazy(() => import("../pages/registrationsuccess"));
const PreviewPage = lazy(() => import("../pages/previewpage"));
const AdminPage = lazy(() => import("../pages/admin"));
const Logout = lazy(() => import("../pages/logout"));



const router = createBrowserRouter([
  {
    path: "auth",
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "registration-success",
        element: <RegistrationSuccess />,
      },
      {
        path: "verify/redirect",
        element: <Verify />,
      }
    ]
  },

  {
    path: "/",
    element: (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    ),
    children: [
      {
        index: true,
        element: <ApplicationPage />,
        errorElement: <>Something terrible has happened</>,
      },
      {
        path: "admin",
        element: <AdminLayout>
          <AdminPage />,
        </AdminLayout>
      },
      {
        path: "student",
        element: <StudentInfo />,
      },
      {
        path: "admission",
        element: <Admission />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "form",
        element: (
          <FormsLayout>
            <Outlet />
          </FormsLayout>),
        children: [
          {
            path: "Bio-Data",
            element: <Biodata />,
          },
          {
            path: "Examination",
            element: <Examination />,
          },
          {
            path: "O-levels",
            element: <Olevels />,
          },
          {
            path: "Preview",
            element: <PreviewPage />,
          },
        ]
      }
    ],
  },
]);

export default router
